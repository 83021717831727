import React from 'react';
import { Zoom } from 'react-reveal';
import './App.css';
import './fonts.css';
import vigesSVG from './images/viges.svg';

function App() {
  const whatsappLink = "https://wa.me/573196894386";

  return (
    <div className="App">
      <header className="App-header d-flex flex-column justify-content-center align-items-center">
        <div className="header-background">
          <Zoom>
            <img style={{
              borderRadius: '50%',
              width: '250px',
              height: '250px',
              objectFit: 'cover',
            }}
              alt={"logo"}
              src={vigesSVG}
            />
          </Zoom>
          <Zoom>
            <h1 style={{ fontFamily: 'Baloo', fontWeight: 'bold' }}>Viges Repostería</h1>
            <p style={{ fontFamily: 'FarmNew' }}>Galletas de limón - Panochas - Galleticas</p>
            <button className='btn btn-light'
              style={{
                padding: "20px",
                fontSize: "20px",
                fontFamily: 'Baloo'
              }}
              onClick={() => {
                window.open(whatsappLink)
              }}
            >
              <i className="fa-brands fa-whatsapp"></i> Hacer mi pedido
            </button>
          </Zoom>
          <Zoom>
            <div className='row'>
              <div className='col-3'>
                <button className='btn btn-flat'
                  style={{
                    color: "white",
                    padding: "10px",
                    fontSize: "50px"
                  }}
                  onClick={() => {
                    window.open("https://instagram.com/vigesreposteria")
                  }}
                >
                  <i className='fa-brands fa-instagram' />
                </button>
              </div>
              <div className='col-3'>
                <button className='btn btn-flat'
                  style={{
                    color: "white",
                    padding: "10px",
                    fontSize: "50px"
                  }}
                  onClick={() => {
                    window.open("https://tiktok.com/@vigesreposteria")
                  }}
                >
                  <i className='fa-brands fa-tiktok' />
                </button>
              </div>
              <div className='col-3'>
                <button className='btn btn-flat'
                  style={{
                    color: "white",
                    padding: "10px",
                    fontSize: "50px"
                  }}
                  onClick={() => {
                    window.open("https://x.com/vigesreposteria?s=11")
                  }}
                >
                  <i className='fa-brands fa-x' />
                </button>
              </div>
              <div className='col-3'>
                <button className='btn btn-flat'
                  style={{
                    color: "white",
                    padding: "10px",
                    fontSize: "50px"
                  }}
                  onClick={() => {
                    window.open("https://www.youtube.com/@vigesreposteria")
                  }}
                >
                  <i className='fa-brands fa-youtube' />
                </button>
              </div>
            </div>
          </Zoom>
        </div>
      </header>
      <footer className="bg-dark text-white text-center p-3">
        <p style={{ fontFamily: 'FarmNew' }}>Rionegro, Antioquia</p>
        <p style={{ fontFamily: 'FarmNew' }}>© 2024 Viges Repostería. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
